import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { navigation, kambariai } from "src/data";
import Header from "components/Header";
import Main from "components/Main";
import Footer from "components/Footer";
import Button from "components/Button";
import About from "components/About";
import FloatGallery from "components/FloatGallery";

const Kambariai = () => {
  const data = useStaticQuery(graphql`
    query KambariaiImages {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ex1: file(relativePath: { eq: "kambariai1.jpg" }) {
        ...fluidImage
      }
      ex2: file(relativePath: { eq: "kambariai4.jpg" }) {
        ...fluidImage
      }
      ex3: file(relativePath: { eq: "kambariai3.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <>
      <Header
        items={navigation}
        logo={data.logo.childImageSharp.fluid}
        logoAlt="Sodyba Kudrenai Logotipas"
        navPosition="right"
      />
      <Main>
        <About
          heading={kambariai.heading}
          firstParagraph={kambariai.firstParagraph}
          secondParagraph={kambariai.secondParagraph}
          buttonText={kambariai.buttonText}
          buttonUrl={kambariai.buttonUrl}
        />
        <FloatGallery
          imageData={[
            data.ex1.childImageSharp.fluid,
            data.ex2.childImageSharp.fluid,
            data.ex3.childImageSharp.fluid,
          ]}
          imageAlt={[
            "Sodyba kudrenai kambariai",
            "Sodyba kudrenai kambariai vidus",
            "Prabangi sodyba Kaune",
          ]}
        />
        <Button url="/kontaktai">Rezervuoti vizitą</Button>
      </Main>
      <Footer />
    </>
  );
};

export default Kambariai;
